<template>
  <div style="min-height: 100%;background-color: #f7f7f7;min-width: 900rem;padding-bottom: 60rem;padding-left: 27rem">
    <div class="flex">
      <div style="width: 50%;margin-right: 29rem">
        <div class="padding" style="height: 163rem;padding-right: 20rem">
          <h3 style="margin: 20rem 0 30rem">心理预约咨询统计</h3>
          <div class="flex flex-align mt-20">
            <el-date-picker
                v-model="formData1.time"
                @change="timeChange"
                size="small"
                type="monthrange"
                :clearable="false"
                value-format="yyyy-MM"
                range-separator="-"
                start-placeholder="开始年月"
                end-placeholder="结束年月">
            </el-date-picker>
            <div class="flex flex-align">
              <p class="c0-6 f-14" style="margin: 0 20rem;word-break: keep-all">学校</p>
              <el-select size="small" v-model="formData1.school_id" placeholder="请选择" @change="getData">
                <el-option
                    v-for="item in schoolConfig"
                    :key="item.id"
                    :label="item.school_name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div v-if="dataCont" class="padding sum-box" style="height: 335rem;margin-top: 34rem">
          <h4>心理预约总数</h4>
          <div class="flex">
            <div class="flex1 border-right">
              <p>心理预约咨询</p>
              <p class="num" style="color: #5470c6">
                <countTo :startVal='0' :endVal='dataCont.psychology_total.total' :duration='2000'></countTo>
              </p>
            </div>
            <div class="flex1 border-right">
              <p>取消/拒绝咨询</p>
              <p class="num" style="color: #ee6666">
                <countTo :startVal='0' :endVal='dataCont.psychology_total.cancel' :duration='2000'></countTo>
              </p>
            </div>
            <div class="flex1">
              <p>实际咨询</p>
              <p class="num" style="color: #9fe080">
                <countTo :startVal='0' :endVal='dataCont.psychology_total.complete' :duration='2000'></countTo>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 532rem" class="padding flex1">
        <h4>各类咨询主题总数</h4>
        <div style="position: relative">
          <div id="pie" ref="pie"></div>
          <div v-if="dataCont && !dataCont.psychology_theme.length" class="no-data">
            <t-result/>
          </div>
        </div>
      </div>
    </div>

    <div class="flex" style="margin-top: 20rem">
      <div class="padding" style="width: 50%;margin-right: 29rem">
        <h4>学生心理咨询数据统计</h4>
        <div style="position: relative">
          <div ref="line1" id="line1" class="line-box"></div>
          <div v-if="!lineObj.line1.length" class="no-data">
            <t-result/>
          </div>
        </div>
      </div>
      <div class="flex1 padding">
        <h4>老师心理咨询数据统计</h4>
        <div style="position: relative">
          <div ref="line2" id="line2" class="line-box"></div>
          <div v-if="!lineObj.line2.length" class="no-data">
            <t-result/>
          </div>
        </div>
      </div>
    </div>

    <div class="padding mt-20" style="padding: 0 0 60rem;">
      <list-template
          :loading="loading"
          @onSearch="onSearch"
          :table-config="tableConfig"
          :table-data="tableData"
          :total="total"
          :current-page="page"
          @onChangePage="handleCurrentChange"
          @onExportData="toXlsx"
      >
        <template slot="title">
          <div class="flex flex-align mb-20" style="padding-right: 40rem">
            <el-date-picker
                v-model="formData2.time"
                type="monthrange"
                size="small"
                :clearable="false"
                value-format="yyyy-MM"
                range-separator="-"
                start-placeholder="开始年月"
                end-placeholder="结束年月">
            </el-date-picker>
            <p style="margin-right: 20rem"></p>
            <el-select v-model="formData2.school_id" placeholder="请选择学校" size="small">
              <el-option
                  v-for="item in schoolConfig"
                  :key="item.id"
                  :label="item.school_name"
                  :value="item.id">
              </el-option>
            </el-select>
            <p style="margin-right: 20rem"></p>
            <el-select v-model="formData2.type" placeholder="请选择统计对象" size="small">
              <el-option
                  v-for="item in personOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div style="margin-left: 20rem">
              <el-input
                  v-model="formData2.name"
                  type="text"
                  placeholder="请输入姓名"
                  size="small"/>
            </div>
            <div class="flex ml-20">
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <el-button debounce size="small" style="margin-left: 16rem" @click.prevent="onReset">重置</el-button>
            </div>
          </div>
        </template>
      </list-template>
    </div>


  </div>
</template>

<script>
import countTo from 'vue-count-to';
import * as echarts from 'echarts';
import {mapState} from "vuex";
import moment from "moment";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"index","meta":{"title":"数据展示","style":0}}},
  name: "index",
  components: {countTo},
  data() {
    return {
      schoolConfig: [],
      formData1: {
        time: [],
        school_id: ''
      },

      dataCont: '',  //除底部表格外的全部数据

      pieCharts: null,

      pieLegend: {},
      pieLegend1: {},
      pieLegend2: {},

      pieSeries: {},
      pieSeries1: {},
      pieSeries2: {},

      options: [],

      lineCharts: null,
      lineXAxis: [],
      lineObj: {
        line1: [],
        line2: [],
      },

      personOptions: [
        {label: '学生', value: 1},
        {label: '教师', value: 2},
      ],

      loading: false,
      toXlsxLoading: false,
      isFullMonth2: true,
      monthOptions2: [],
      formData2: {
        time: [],
        school_id: '',
        type: 2,
        name: ''
      },
      tableConfig: [
        {prop: "name", label: "姓名"},
        {prop: "total", label: "心理咨询预约"},
        {prop: "apply", label: "预约申请"},
        {prop: "invitation", label: "预约邀请"},
        {prop: "complete", label: "实际咨询"},
        {prop: "cancel", label: "取消咨询"},
        {prop: "refuse", label: "拒绝咨询"},
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
    }
  },
  created() {
    this.$store.commit("setPage", 1);

    //数据初始化 默认值为当前年当前月
    let str = moment().format('yyyy-MM');
    this.formData1.time = [str, str]
    this.formData2.time = [str, str]

    this.pieLegend1 = {
      right: '30',
      orient: 'vertical',
      itemWidth: 15,
      itemHeight: 15,
      icon: "circle",
      itemGap: 16,
      top: 30,
      type: 'scroll'
    }
    this.pieSeries1 = {
      radius: ['70%', '90%'],
      right: '200',
      top: 60,
    }
    this.pieLegend2 = {
      right: '30',
      orient: 'vertical',
      itemWidth: 15,
      itemHeight: 15,
      icon: "circle",
      itemGap: 16,
      top: 30,
      type: 'scroll'
    }
    this.pieSeries2 = {
      radius: ['65%', '80%'],
      right: '200',
      top: 40,
    }

    if (window.innerWidth && window.innerWidth > 1450) {
      this.pieLegend = this.pieLegend1
      this.pieSeries = this.pieSeries1
    }
    if (window.innerWidth && window.innerWidth <= 1450) {
      this.pieLegend = this.pieLegend2
      this.pieSeries = this.pieSeries2
    }

  },
  mounted() {
    this.getSchool()

    this.getData()

    this.getTableData()

    //1450分辨率下饼图的适应
    window.addEventListener('resize', this.aaa)

  },
  computed: {
    ...mapState(["page"])
  },
  destroyed() {
    window.removeEventListener('resize', this.aaa)
  },
  methods: {

    aaa: tools.debounce(function (){
      if (window.innerWidth && window.innerWidth > 1450) {
        this.pieLegend = this.pieLegend1
        this.pieSeries = this.pieSeries1
      }
      if (window.innerWidth && window.innerWidth <= 1450) {
        this.pieLegend = this.pieLegend2
        this.pieSeries = this.pieSeries2
      }
      setTimeout(() => {
        this.pieInit()
      }, 1)
    }, true, 500),

    getSchool() {
      this.$_axios.get('site/school').then(res => {
        this.schoolConfig = [{id: 0, school_name: "全部"}, ...res.data.data];
        this.formData1.school_id = 0
      })
    },

    getData() {
      let params = {
        school_id: this.formData1.school_id,
        start_at: this.formData1.time[0],
        end_at: this.formData1.time[1]
      }
      this.$_axios2('/api/evaluation/appointment/statistics', {params}).then(res => {
        if(res.data.status === 0){
          let data = res.data.data
          this.dataCont = data;

          this.lineXAxis = data.abscissa;
          setTimeout(() => {
            this.pieInit()
            this.lineInit(data.psychology_student, 'line1')
            this.lineInit(data.psychology_teacher, 'line2')
          },1)
        }
      })
    },

    timeChange(e) {
      if (e) this.getData()
    },

    pieInit() {
      let data = this.dataCont.psychology_theme;
      let _data = data.map(item => {
        return {
          value: item.total,
          name: item.theme_name
        }
      })

      let myChart = echarts.getInstanceByDom(this.$refs['pie'])
      if (myChart == null) {
        myChart = echarts.init(this.$refs['pie']);
      }

      let option;

      option = {
        color: tools.echartsColor,
        tooltip: {
          trigger: 'item'
        },
        legend: this.pieLegend,
        series: [
          {
            name: '咨询主题总数',
            type: 'pie',
            ...this.pieSeries,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
              }
            },
            labelLine: {
              show: false
            },
            data: _data
          }
        ]
      };

      option && myChart.setOption(option, true)
    },
    lineInit(data = [], dom) {
      let _xAxis = this.lineXAxis;
      let legend_name = [], _series = [];
      let arr = data.filter(i => i.list.length)
      if(arr.length) {
        _series = arr.map(item => {
          legend_name.push(item.name)
          return {
            name: item.name,
            data: item.list.map(_item => [_item.subscribe_at, _item.total]),
            type: 'line',
          }
        })
      }
      this.lineObj[dom] = _series

      let myChart = echarts.getInstanceByDom(document.getElementById(dom))
      if (myChart == null) {
        myChart = echarts.init(this.$refs[dom]);
      }

      let option;
      option = {
        grid: {
          left: '60',
          right: 50,
          top: '120',
          bottom: '40'
        },
        xAxis: {
          type: 'category',
          data: _xAxis,
          axisTick: {
            show: true,
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#C9CDD4'
            }
          },
          axisLabel: {
            color: '#86909C'
          }
        },
        yAxis: {
          name: '咨询量',
          nameTextStyle: {
            align: 'right',
            lineHeight: '50',
            color: '#86909C'
          },
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            color: '#86909C'
          }
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          alwaysShowContent: false,
          // backgroundColor: '#3491FA',
          backgroundColor: '#fff',
          borderWidth: 0,
          className: 'tooltip',
          textStyle: {
            color: '#333',
            lineHeight: 50,
            textAlign: 'center'
          },
          padding: [8, 15, 8, 15,],
          formatter: params => {
            let str = `<p><span class="cir cir-1"></span>时间：${params[0].axisValue}</p>`;
            params.forEach((item,index) => {
              let _str = `<p class="mt-10"><span class="cir cir-2"></span>${item.seriesName}：${item.data[1]}</p>`;
              str += _str;
            })
            return str
          }
        },
        legend: {
          data: legend_name,
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
          itemGap: 20,
          top: 30,
          right: 50
        },
        series: _series
      };

      option && myChart.setOption(option, true)
    },

    getTableData() {
      this.loading = true;
      if (!this.formData2.time) {
        let str = moment().format('yyyy-MM');
        this.formData2.time = [str, str];
      }
      let params = {
        start_at: this.formData2.time[0],
        end_at: this.formData2.time[1],
        school_id: this.formData2.school_id,
        type: this.formData2.type,
        name: this.formData2.name,
        page: this.page
      }
      this.$_axios2('/api/evaluation/appointment/statistics-list', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list;
        this.total = data.page.total
      }).finally(() => {
        this.loading = false;
      })
    },

    toXlsx() {
      this.toXlsxLoading = true
      if (!this.formData2.time) {
        let str = moment().format('yyyy-MM');
        this.formData2.time = [str, str];
      }
      let params = {
        start_at: this.formData2.time[0],
        end_at: this.formData2.time[1],
        school_id: this.formData2.school_id,
        type: this.formData2.type,
        name: this.formData2.name
      }
      this.$_axios2.get('api/evaluation/appointment/export', {params}).then(res => {
        tools.download('心理咨询数据统计', res.data)
      }).finally(() => {
        this.toXlsxLoading = false
      })
    },

    onSearch(val) {
      this.loading = true
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getTableData(val)
    },

    onReset() {
      let str = moment().format('yyyy-MM');
      this.formData2.time = [str, str];
      this.formData2.type = 2;
      this.formData2.school_id = ''
      this.formData2.name = ''
      this.onSearch()
    },

    handleCurrentChange(val) {
      this.$store.commit("setPage", val);
      this.getTableData()
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .cir {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  transform: translateY(-1rem);
  border-radius: 50%;
  margin-right: 8rem;
}

::v-deep .cir-1 {
  background-color: rgb(84, 112, 198);
}

::v-deep .cir-2 {
  background-color: rgb(238, 102, 102);
}

.c0-6 {
  color: rgba(0, 0, 0, .6);
}

.padding {
  background-color: #fff;
  border-radius: 10rem;
  padding: 20rem 0 24rem 24rem;
}

h3 {
  font-size: 24rem;
  color: rgba(0, 0, 0, .8);
}

h4 {
  font-size: 20rem;
}

.sum-box {
  p {
    text-align: center;
    color: rgba(0, 0, 0, .6);
    font-size: 20rem;
    margin-top: 52rem;
  }

  .num {
    text-align: center;
    font-size: 48rem;
    color: #165DFF;
    margin-top: 66rem;
  }

  .border-right {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 64rem;
      right: 0;
      width: 1rem;
      height: 147rem;
      background-color: #eee;
    }
  }
}

#pie {
  width: 100%;
  height: 458rem;
}

.line-box {
  height: 450rem;
}

.p1 {
  word-break: keep-all;
  color: rgba(0, 0, 0, .6);
  font-size: 14rem;
  margin: 0 22rem;
}

.no-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>
